import { useStaticQuery, graphql } from "gatsby"

export const SiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(
    graphql`
      query SiteMetaData {
        sanitySiteConfig {
          footerText
          title
          description
          url
          phone
          address
          email
          logo {
            asset {
              url
            }
          }
          mainNavigation {
            name
            url
          }
          footerNavigation {
            name
            url
            dropdownItems {
              name
              url
            }
          }
        }
      }
    `
  )
  return sanitySiteConfig
}
